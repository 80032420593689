// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bM_k4";
export var caseStudyBackground__lineColor = "bM_kW";
export var caseStudyHead = "bM_k6";
export var caseStudyHead__imageWrapper = "bM_kT";
export var caseStudyProjectsSection = "bM_k5";
export var caseStudyQuote__bgLight = "bM_k2";
export var caseStudyQuote__bgRing = "bM_k1";
export var caseStudyVideoReview = "bM_l2";
export var caseStudyVideoReview__bgRing = "bM_l3";
export var caseStudyVideo__ring = "bM_k8";
export var caseStudy__bgDark = "bM_kS";
export var caseStudy__bgLight = "bM_kR";
export var caseStudy__bgLightReverse = "bM_l1";